﻿@charset 'utf-8';
// VARIBALES

// layers
$z-index-preloader: 2000; // even more blocking than completley (?)
$z-index-top: 1000; // completley blocking
$z-index-nav: 200; // nav menu (because 100 is the pagepiling dots nav)
$z-index-nav-bg: 199; // nav menu (because 100 is the pagepiling dots nav)
$z-index-lang: 19; // footer language menu
$z-index-cookie: 18; // cookie banner
$z-index-captcha: 17; // captcha badge
$z-index-interface: 16; // header & footer
$z-index-cmp-cookie: 16; // CMP cookie banner
$z-index-control: 15; // above content below menus
$z-index-interface-bg: 14; // header and footer bg
$z-index-slide-4: 11;
$z-index-slide-3: 12;
$z-index-slide-2: 13;
$z-index-slide-1: 14;

// colors
$color-main: #154479;
$color-main-dark: scale-color($color-main, $lightness: -80%);
$color-text: #444444;
$color-text-page:#515151;
$color-text-sidebar: #5c5c5c;
$color-secondary: #c8ab65;
$color-button-text: #23211c;
$color-white: #ffffff;
$color-black: #000000;
$color-blue-light: #11afff;
$color-blue-dark: #0c2780;
$blue-linear-gradient: linear-gradient(115deg, $color-blue-dark 49.9%, transparent 50%);


// MIXINS

// debug layout
@mixin debug-layout(){
	$selector: "form ";
	@for $i from 1 through 10 { 
		$num: $i * 50;
		#{$selector} { outline:2px dotted hsl($hue:$num, $saturation:90%, $lightness:50%); }
		$selector: $selector + "> * ";
	}
}

// debug viewport
@mixin debug-view($type:media) { // align-content: bunch of debug styles that were on base.scss
	body:before { display:block; position:fixed; padding:3px 12px; font-size:11px; border-radius:16px; bottom:6px; right:6px; color:$color-white; z-index:1000000; }
	@if $type == media {
		body:before { 
			@include breakpoint(small){ background-color:#ff0000; content:"small"; }
			@include breakpoint(medium){ background-color:#ff5000; content:"medium"; }
			@include breakpoint(large){ background-color:#0094ff; content:"large"; }
			@include breakpoint(xlarge){ background-color:#b200ff; content:"xlarge"; }
			@include breakpoint(xxlarge){ background-color:#ff006e; content:"xxlarge"; }
		}
	}
}

// general debug (using selector list)
@mixin debug-list($class:".my-class", $list:#{".selector-1"}){ //TODO: refine this
	#{$class} {
		@for $i from 1 through length($list) { 
			$num: $i * 100/length($list);
			#{nth($list, $i)} { 
				background:hsl($hue:$num, $saturation:80%, $lightness:80%) !important ; outline:1px dotted hsl($hue:$num, $saturation:80%, $lightness:50%);
			 }
		}
	}
}

//@include debug-view();
//@include debug-layout();
//@include debug-list("form","ul");


// STYLES

// pace.js preloader theme, get from https://github.hubspot.com/pace/docs/welcome/
.pace { display: none; // position: fixed; margin: auto; top: 0; left: 0; right: 0; bottom: 0; height: 5px; width: 200px; background: #fff; border: 1px solid #29d; overflow: hidden; 
	//.pace-progress { box-sizing: border-box; transform: translate3d(0, 0, 0); max-width: 200px; position: fixed; z-index: 2000; display: block; position: absolute; top: 0; right: 100%; height: 100%; width: 100%; background: #29d; }
	&.pace-inactive { display: none; }
}

.menu-slides { color: $color-white;
	a {
		span { border-color: $color-white; }
	}
}

#pp-nav { color: $color-white;
	span { border-color: $color-white; }
	li.active span { background:$color-white;}
}

#preloader { pointer-events: none; user-select: none; z-index: 2000; position: fixed; width: 100%; height: 100%; z-index: 1000; background-color: #2b2b2b; display: flex; align-items: center; background-size: cover; background-position: 50% 50%; }

// https://icons8.com/cssload
.cssload {
	&-thecube { width: 73px; height: 73px; margin: 0 auto; margin-top: 49px; position: relative; transform: rotateZ(45deg); }
	&-cube { position: relative; transform: rotateZ(45deg); float: left; width: 50%; height: 50%; position: relative; transform: scale(1.1);
		&:before { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: $color-secondary; animation: cssload-fold-thecube 2.76s infinite linear both; transform-origin: 100% 100%; }
	}
	&-c2 { transform: scale(1.1) rotateZ(90deg); 
		&:before { animation-delay: 0.35s; }
	}
	&-c3 { transform: scale(1.1) rotateZ(180deg); 
		&:before { animation-delay: 0.69s; }
	}
	&-c4 { transform: scale(1.1) rotateZ(270deg);
		&:before { animation-delay: 1.04s; }
	}
}

@keyframes cssload-fold-thecube {
	0%, 10% { transform: perspective(136px) rotateX(-180deg); opacity: 0; }
	25%, 75% { transform: perspective(136px) rotateX(0deg); opacity: 1; }
	90%, 100% { transform: perspective(136px) rotateY(180deg); opacity: 0; }
}

// some general styles resets for pagepiling
html, body, form { height: 100%; }
body { overflow-x: hidden;
	&.home { overflow:hidden; }
} // https://get.foundation/sites/docs/xy-grid.html#grid-container-full

// General
body { font-family: 'Rubik', Arial, Helvetica, sans-serif; font-weight:300; min-height:480px; //300,400,500
	&::-webkit-scrollbar-track { background-color: #fff; }
	&::-webkit-scrollbar { width: 5px; background-color: #fff; }
	&::-webkit-scrollbar-thumb { background-color: $color-secondary; }
} 
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { font-family: 'Rubik', sans-serif; } //400,500

.image-wrapper { text-align:center; margin-top:2rem; margin-bottom:2rem; }

#wrap { position: relative; width: 100%; overflow: hidden; }

form { position: relative; width: 100%; margin: 0;  display: grid; grid-template-rows: 5.25rem 1fr minmax(4rem, auto); grid-template-columns:1rem minmax(328px,1440px) 1rem; // the layout is optimized for minimum width of 360px 
	@include breakpoint(medium) {  grid-template-rows: 6.25rem 1fr 4rem; grid-template-columns:1fr minmax(328px,1440px) 1fr; }
	grid-template-areas:
		". header ."
		". content ."
		". footer .";
	.page & { grid-template-rows: 5.25rem auto 1fr 4rem; grid-template-columns:1rem minmax(328px,1440px) 1rem;
		grid-template-areas:
			". header ."
			". hero ."
			". content ."
			". footer ."; // pages contain a hero image
		@include breakpoint(medium) {
			grid-template-rows: 5.25rem auto 1fr 4rem; grid-template-columns:1fr minmax(328px,1440px) 1fr;
		}
	}
	&.elevate { z-index:$z-index-cookie; 
		@include breakpoint(medium){ z-index:initial; }
	} // to prevent captcha blocking cookie banner
}

// Header
.bg-header { grid-area:1/1/2/4; background-color: $color-white; color:#154479; position:relative; z-index:$z-index-interface-bg;  box-shadow:0 10px 30px 0 rgba(0,0,0,0.15); }
header { grid-area:header; display:flex; justify-content:space-between; align-items:center; position:relative; z-index: $z-index-interface; padding: 0; color: #154479;
	@include breakpoint(medium) { padding: 1rem 4rem; }
	h1 { flex: 0 1 180px; margin:0; line-height:0;
		@include breakpoint(medium) { flex-basis:240px; }
	}
	a { color: #154479; text-align: center; }
	.icon-menu7 { height:1.75rem; width:1.75rem; flex:0 0 1.75rem; }
	#menuOpen { line-height:.5;  // fixes flex align-items:center bug
		@include breakpoint(medium) { line-height:initial; }
	}
}

.main-menu { flex: 1 1 50%; text-align: right; //padding-right: 2rem;
	a { text-transform: uppercase; font-weight: 400; padding-right:2em; display:inline-block;
		&.signup { display:none; 
			@include breakpoint(medium) { display:inline; }
		}
	}
	
}
.icon { display: inline-block; width: 1rem; height: 1rem; stroke-width: 0; stroke: currentColor; fill: currentColor; }

// Menu
.menu a, .menu .button { text-decoration: none; display:block;  line-height:1; padding: 0.7rem 1rem } // copied just this from @include foundation-menu, so i won't habe to include the whole module
nav.menu { z-index: $z-index-nav; position:fixed; right:0; top:0; bottom:0; overflow-x:none; overflow-y:auto; width: 90vw; min-width:100px; padding: 1rem; transform: translateX(100%); display: flex; flex-direction: column; transition: all ease-in 0.25s;
	background-color: scale-color($color-main, $alpha: -2.5%);
	// Due to a bug in chrome backdrop filter is temporarly removed. will revisit later.
	/*@supports (backdrop-filter: none) {
		background-color: scale-color($color-main, $alpha: -20%);
		backdrop-filter: blur(10px) contrast(150%); 
	}*/
	will-change: transform; // https://developers.google.com/web/updates/2017/10/animated-blur
	&::-webkit-scrollbar-track { background-color: $color-main; }
	&::-webkit-scrollbar { width: 5px; background-color: $color-main; }
	&::-webkit-scrollbar-thumb { background-color: $color-secondary; }

	 @include breakpoint(medium){ width:65vw; min-width:25rem; max-width:33rem; padding: 1.5rem; } 
	 @include breakpoint(large){ width:45vw; max-width:33rem;  padding: 1.75rem; } 
	 @include breakpoint(xlarge){ width:33vw; max-width:33rem; } 
	.mobileMenu & { transform: translateX(0); }
	#menuClose { position: absolute; right: 0; top:0; padding: 1.5rem; } // TODO: get rid of this ID after post-login redesign
	.icon { width: 1.75rem; height: 1.75rem; }
	div { flex: 1 0 auto; display: flex; flex-direction: column; justify-content: flex-start;
		@include breakpoint(medium){ justify-content: space-between; }
	}
	ul { list-style-type: none; margin: 0; padding: 0;
		&.main-links { flex:1 0 50%; }
		&.info-links { display: flex; flex-wrap: wrap; align-items:center; padding-bottom: 0; margin:auto 0 0 0;
			@include breakpoint(medium){ margin:0; }
			li { flex: 1 0 50%; font-size:.875rem; padding:.25em; font-weight: 300; 
				 @include breakpoint(medium){ font-size:1rem; padding:.5em; }
				 &:last-child { flex-grow:0; }
			}
			a, .link {  font-weight:300; padding:.5em; line-height:1.3; }
			.cookie-settings-link { color:$color-white; cursor:pointer; width:100%; text-align:left;
				&:hover { background-color:transparentize($color-white, 0.9); }
			}
		}
	}
	li { display: block; margin: 0; padding: .5em 0; font-size: 1.5rem; }
	a { color: $color-white;
		&:hover { background-color:transparentize($color-white, 0.9); }
	}

	.language {
		&-menu { flex:0 1 auto; font-size: 1.5rem; font-variant: small-caps; //display:none; //TODO: finish menu
			a { text-decoration: none; color: $color-white; display:flex;
				span { order: 1; position:relative;
					&:after { content: ""; height: 0; width: 0; opacity:0.5; border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 6px solid $color-white; display: block; position: absolute; right: -32px; top: 10px; }
				}
				svg { width:1.125rem; margin:0 .5em 0 0; order:0; } //vertical-align:-7%; height:1.125rem; 
			}
			&.closed a span:after { border-top: 6px solid $color-white; border-bottom:none; }
			@include breakpoint(medium){ display:none; }
			h5 { display:none; //font-size: .875rem; flex: 0 0 100%; font-weight: 300; display: flex; justify-content: center; padding:.5em; color:$color-white;
			span { flex: 1 0 50%; text-align: center; 
				
			}
			a { display:none; } //font-size: 1rem; opacity:0.5; 
			}
			ul { margin: 0; padding: 0; list-style-type: none; display: flex; flex-wrap: wrap; overflow:hidden; max-height:100em; background-color:rgba(255,255,255,0.1); transition:all 1s ease-in-out; }//border:1px solid red;  
			&.closed ul {  max-height:6em;
				li {  overflow:hidden; max-height:0; margin:0; //display:none;
					/*&.lang-en { order:2; display:block; max-height:4em; }
					&.lang-de { order:3; display:block; max-height:4em; }
					&.lang-es { order:4; display:block; max-height:4em; }
					&.lang-nl { order:5; display:block; max-height:4em; }*/
					&.language-toggler { order:1; display:block; max-height:4em; }
				}
			}
			li { margin: 0; padding: 0; white-space: nowrap; flex: 0 0 25%; text-align: center; max-height:4em; transition:all .5s ease-in-out;
				//&:nth-child(n+5){ display:none; }
				a { line-height:1.5; display:inline-block; min-width:2em; vertical-align:25%; padding:.25em;
					&:hover { background: rgba(255, 255, 255, 0.25); }
				}
			}
		}
		&-indicator { margin-right:3rem; }
		
	}

	.follow-us { flex:0 0 2rem; padding-bottom:2rem;
		svg { margin:0 .5em; vertical-align:-50%;}
	}
	.copyright { font-size:.75rem; color:transparentize($color-white, 0.5); padding:1rem 1rem 0; margin:0; 
		@include breakpoint(medium){ display:none;}
	}
}
.nav-bg { position:fixed; height:100vh; width:100vw; z-index:$z-index-nav-bg; cursor:pointer; display:none;
	&.show { display: block; }
}

// Homepage
#pagepiling { grid-area: 2/1/4/4; position: relative;
	@include breakpoint(medium){grid-area: 2/1/3/4;}
	.section { display:flex; align-items:flex-start;// overflow-y:scroll;
		@include breakpoint(medium){ justify-content:center; align-items:stretch;
			@media screen and (max-height: 42rem) { background-size: auto !important; }
		}
		> div { flex:0 1 100%; max-width:1440px; min-height:45%; padding:3.5rem 2.5rem; display:flex; z-index:$z-index-interface-bg;
			@include breakpoint(medium){ padding: 3rem 3rem 8rem 3rem; min-height:0; }
			//&:after { content:""; display:block; position:absolute; bottom:150px; left:0; width:100%; height:150px; border:1px solid red; background-color:rgba(255,0,0,0.15);}
		}
		//&.active, &.active ~ .section {  > div { padding:3.5rem 2.5rem; } }
		h2 { color:$color-white; font-size:2.5rem; font-weight:500; font-style:italic; line-height:1.2; padding:.3125em 0; margin:0; position:relative;
			@include breakpoint(medium){ font-size: 2rem; }
			@include breakpoint(large){ font-size:5vh; } //3.5rem
			@include breakpoint(xlarge){ font-size: 6vh; }
			@include breakpoint(xxlarge){ font-size: 3.5rem; }
			@media screen and (max-height: 40rem) { font-size:2rem; } // don't go lower that this
		}
		h3 { color:#87d1f0; font-size:1rem; font-weight:400; margin-bottom:1em; //text-transform:uppercase; 
			@include breakpoint(medium){ font-size: 1.5rem; }
			@include breakpoint(large){ font-size: 1.5rem; }
		}
		h4 {  font-size:1.5rem; font-style:italic; font-weight:500; margin-bottom:1em; color:$color-white; 
			@include breakpoint(medium){ font-size: 1.75rem; }
			@include breakpoint(large){ font-size: 2.125rem; }
		}


	}

	.intro { z-index:$z-index-slide-1 !important; flex-direction:column; // background-size: cover;
		@include breakpoint(medium){ flex-direction:row; background-image: $blue-linear-gradient, url(../Images/img/hero-intro.jpg); // image with diagonal gradient
			background-repeat:no-repeat; background-size:auto 100%; background-position:right bottom, right -30vw bottom; 
		} 
		@include breakpoint(large){ background-position:right bottom, right -15vw center; }
		@include breakpoint(xxlarge){ background-position:right bottom, right center; }
		@media screen and (min-width: 120.01rem) { background-position:right bottom, right -3vw center; }
		@include breakpoint(medium){ 
			@media screen and (max-height: 42rem) { background-position:right bottom, right -40vw center; }
			@include breakpoint(portrait) { background-image: $blue-linear-gradient, url(../Images/img/hero-intro-mobile.jpg);
				background-size:auto 100%; background-position: bottom right, bottom right -5vw;
			}
		} 
		&:before { content:""; display:block; background-image:url(../Images/img/hero-intro.jpg); background-repeat:no-repeat; background-position:center center; background-size:auto 120%; order: 2; flex:1 1 auto; width: 100%;
			@include breakpoint(medium){ background: $blue-linear-gradient, 
				radial-gradient(circle at 55% 50%, rgba(75,154,248,0.7) 0%, rgba(75,154,248,0.1) 30%, transparent 40%);
				mask-image:$blue-linear-gradient; position:absolute; height:100%;
			}
		}
		> div { display:flex; background: $color-blue-dark; flex: 0 1 auto; width: 100%;
			@include breakpoint(medium){ align-items:center; background:none; flex:0 1 100%; width:auto; }
			// Uncomment to restore phone image 
			//@include breakpoint(medium){ background-position:40vw center; background-size:50vw; background-image: url(../Images/img/mobile.png); }
			//@include breakpoint(large){ background-position:center center; background-size: auto 45vw;}
			//@include breakpoint(xlarge){ background-position:center center; background-size: 50%;}
		}
		&-text { flex:0 1 auto;
			@include breakpoint(medium){ flex-basis:45%; }
			@include breakpoint(large){ flex-basis:40%; }
		}

		h2 { font-size:2rem; color:$color-blue-light; font-style:normal; text-transform: uppercase; line-height:1.125; 
			 @include breakpoint(medium){ font-size:2.5rem; }
			//@supports not (shape-outside:circle(50%)) { & { width:45%; }}
			/*@supports (shape-outside:circle(50%)) { // https://developer.mozilla.org/en-US/docs/Web/CSS/@supports
				&:before { content:""; display:block; height:2em; width:50%; float:right; shape-outside:polygon(95% 0px, 100% 0%, 100% 100%, 67% 100%); }
			}*/
		} 
		h3 { color:$color-white; margin-bottom:0; padding:1em 0 0 0;
			.logo-plus500 { width:5em; vertical-align:-5%; margin-right:.25em; float:none; 
				//@include breakpoint(xlarge){ width:7em; }
			}
		}
	}
	.webp & .intro {  background-image:url(../Images/img/hero-intro.webp);
		@include breakpoint(medium){ background-image: $blue-linear-gradient, url(../Images/img/hero-intro.webp); 
			@include breakpoint(portrait) { background-image: $blue-linear-gradient, url(../Images/img/hero-intro-mobile.webp); }
		}
		// Uncomment to restore phone image 
		//> div { 
		//	@include breakpoint(medium){ background-image: url(../Images/img/mobile.webp); }
		//}
	}

	.cpa { background:$color-white  url(../Images/img/cpa.png) no-repeat left bottom/auto 60%; z-index:$z-index-slide-2 !important;
		@include breakpoint(medium){ background: linear-gradient(to right, transparent 49.9%, $color-white 50%), url(../Images/img/cpa.png) no-repeat left -10vw bottom/auto 100%; 
		}
		> div { padding-top:1.5rem; background:$color-white; 
			@include breakpoint(medium){ justify-content:flex-end; padding-top:4rem; margin-bottom:0; background-color:transparent;   }
		}
		&.pp-scrollable > div { margin-bottom:16rem; 
			@include breakpoint(medium){ margin-bottom:0; }
			.join-now { @include breakpoint(medium){ margin-bottom:6rem; } }
		}
		&-text { 
			@include breakpoint(medium){ flex:0 1 50%; padding-left:3rem; }
		}
		h2 { color:$color-main; }
		h3 { color:$color-main; font-size:1.125rem; font-weight:500; }
		p { color:$color-text; line-height:1.6;
			//@include breakpoint(medium){ line-height:2;}
			strong { display:block; margin-bottom:.5em; }
		}
	}
	.webp & .cpa { background:$color-white url(../Images/img/cpa.webp) no-repeat left bottom/auto 60%;
		@include breakpoint(medium){ background: linear-gradient(to right, transparent 49.9%, $color-white 50%), url(../Images/img/cpa.webp) no-repeat left -10vw bottom/auto 100%; }
	}

	.brand { background:$color-white url(../Images/img/brand.png) no-repeat right bottom/100% auto; z-index:$z-index-slide-3 !important;
		@include breakpoint(medium){ 
			background:linear-gradient(to left, transparent 49.9%, $color-white 50%), url(../Images/img/brand-new-bg.png) no-repeat left 15vw center/auto 100%; 
			@media screen and (max-height: 53rem) { background-size: auto!important; }
			&:after { content:''; position:absolute; bottom:4%; right:8%; width:34%; height:4rem; 
				background:rgba(26,58,103,.8) url(../Images/img/brand-new-logo.png) no-repeat center center/80% auto; border:2px solid rgba(255,255,255,.8); border-radius:1.25rem; 
			}
		}
		@include breakpoint(large){ 
			background-position:left, left 18vw center;
		}
		@include breakpoint(xlarge){ 
			background-position:left, left 30vw center;
			&:after { height:5rem; }
		}
		@include breakpoint(xxlarge){ 
			background-position:left, left 37vw center;
			&:after { height:7rem; }
		}
		
		> div { padding-top:1.5rem; background-color:$color-white;
			@include breakpoint(medium){  padding-top:3rem; margin-bottom:0; background-color:transparent; }
		}
		&.pp-scrollable > div { margin-bottom:16rem; 
			@include breakpoint(medium){ margin-bottom:0; }
			.join-now { @include breakpoint(medium){ margin-bottom:6rem; } }
		}
		&-text { 
			@include breakpoint(medium){ flex:0 1 50%; padding-right:3rem; }
		}
		h2 { color:$color-main; }
		h3 { color:$color-main; font-size:1.125rem; font-weight:500; }
		p { color:$color-text; line-height:1.6;
			//@include breakpoint(medium){ line-height:2;}
			strong { font-weight:300; } // TODO: remove the <strong> tag in the resource 
		}
		img { width:7em; padding:0 0 1rem 0; display:block;// margin:0 auto;
			@include breakpoint(medium){ width:8em; margin:0; }
			@include breakpoint(large){ width:10em; vertical-align:-5%; float:left; padding:1.5rem 0.5rem 2rem; border:1px solid $medium-gray; margin:0 0.875rem 0.3125rem 0; }
		}
		.our-brand { display:inline-block; }
	}
	// Current Chicago bulls asset has bad compression, desided to use only png for current brand image, uncomment and update in next brands image update.
	//.webp & .brand { background:$color-white url(../Images/img/brand.webp) no-repeat right bottom/100% auto;
	//	@include breakpoint(medium){ 
	//		background:linear-gradient(to left, transparent 49.9%, $color-white 50%), url(../Images/img/brand-new-bg.webp) no-repeat left 15vw center/auto 100%; 
	//		@media screen and (max-height: 53rem) { background-size: auto!important; }
	//		&:after { background-image:url(../Images/img/brand-new-logo.webp); }
	//	}
	//	@include breakpoint(large){ 
	//		background-position:left, left 18vw center;
	//	}
	//	@include breakpoint(xlarge){ 
	//		background-position:left, left 32vw center;
	//	}
	//	@include breakpoint(xxlarge){ 
	//		background-position:left, left 38vw center;
	//	}
	//}
	.start { background: radial-gradient(circle at 50% 50%, scale-color($color-main, $lightness: 15%) 0%, scale-color($color-main, $lightness: 10%) 25%, $color-main 75%, $color-main 100%);  z-index:$z-index-slide-4 !important;		
		align-items:center;
		@include breakpoint(medium){ background: radial-gradient(circle at 50% 50%, scale-color($color-main, $lightness: 15%) 0%, scale-color($color-main, $lightness: 10%) 25%, $color-main 65%, $color-main 100%);
			align-items:initial; }
		@include breakpoint(xlarge){ background: radial-gradient(circle at 50% 50%, scale-color($color-main, $lightness: 15%) 0%, scale-color($color-main, $lightness: 10%) 25%, $color-main 45%, $color-main 100%); }
		> div { align-items: center; }
		&-text { flex:0 1 100%; text-align:center; }
		h2 { font-size:3rem;
			@include breakpoint(medium){ font-size:4rem; display:inline; }
			&:before { content:""; display:block; position:absolute; top:0; left:0; background-color:$color-secondary; width:2rem; height:.5rem;}
			&:after { content:""; display:block; height:1px; }
		}
	}
}

// 3D Phone (WebGL)
/*canvas { display: block; }
#threejs { border:1px solid #ff0000; min-height:100px; min-width:100px; }*/

   
// Homepage Animation
.pp-section {
	> div > div { opacity: 0; transform: translateY(50%) scale(0.5); transition: all 0.9s; }
	&.active > div > div { transform: translateY(0) scale(1); opacity: 1; }
}

.pp-scrollable { overscroll-behavior:none;
	&::-webkit-scrollbar-track { background-color: #fff; }
	&::-webkit-scrollbar { width: 5px; background-color: #fff; }
	&::-webkit-scrollbar-thumb { background-color: #c69c6d; }
}

#pp-nav, .pp-slidesNav { grid-area: content; position: absolute; display:none;
	@include breakpoint(medium){ display:block; }
	li { font-size: .75rem; width: 1em; height: 1em; padding: 1em .75em;
		&.active span { background:$color-secondary; border:1px solid $color-secondary; } // MS Edge needs both border and background to make a good circle
	}
	span { top: .125em; left: .125em; width: 1em; height: 1em; background:scale-color($color-main, $lightness: 15%); border: 1px solid scale-color($color-main, $lightness: 15%); }
	// &.right { right:auto; margin-top:0 !important; } //span { border-color:$color-white; } 
}

.join-now { background-color: $color-secondary; align-self:flex-start; transform:scale(1) skew(-15deg); font-size:1.25rem; padding:.25em 1.5em; white-space:nowrap; font-weight: 500; color: $color-button-text; text-transform: uppercase; border-radius: .375em;
	// to whomever edits this: enjoy!
	@at-root #{selector-unify(a,&)}{ display:inline-block; text-align:center; transition:all .15s ease-in; //display:block;
		&:hover { color: $color-button-text;  transform: scale(1.125) skew(-15deg); }
	}
	@at-root #{selector-unify(button,&)}{ cursor: pointer; }
	
	&.white { background-color:$color-white; color:$color-blue-dark; }
	
	&:hover {  transform: scale(1.125) skew(-15deg);  } //transition:all .15s ease-in;
	span { display: block; transform: skew(15deg); font-weight:500; }

	.home & {  margin-top:1.5rem; font-size: 1.5rem; padding: .5em 2.5em; transform:scale(1) skew(-30deg); // home buttons are skewed more
		@include breakpoint(medium){ margin-top:1rem; margin-bottom:1rem; }
		span {  transform: skew(30deg); }
		&:hover { transition:transform .15s ease-in; transform: scale(1.125) skew(-30deg);  }
	}
}

.main { grid-area: content; padding:1rem 0; // TEMP padding
	@include breakpoint(medium){ padding:2rem; }
	@include breakpoint(large){ display:flex;  }
	 
	h3 { font-size:1.25rem; font-weight:500;}
	p { color:$color-text-page; }
} 


// Pages
.page {
	.hero { grid-area:2/1/3/4; position:relative; z-index: $z-index-interface-bg; background-size:cover; background-repeat:no-repeat; background-color:$color-main-dark;
		@include breakpoint(medium){ background-size:100% auto;} 	
		&:after { content:""; display:block; position:absolute; top:0; right:0; left:0; height:100%; background-color:rgba(0,0,0,0.5); }
	}

	hgroup { grid-area: hero; padding:30px 0; z-index: $z-index-interface; position:relative;
		@include breakpoint(medium){ padding:50px 4rem; }
		@include breakpoint(large){ padding-top:100px; padding-bottom:100px; }
		h2 { color:$color-white; font-size:2rem; font-weight:500; font-style:italic; padding:.3125em 0 0 0; margin:0; position:relative;
			@include breakpoint(medium){ font-size: 2rem; }
			@include breakpoint(large){ font-size: 2.5rem; }
			
			&:before { content:""; display:block; position:absolute; top:0; left:0; background-color:$color-secondary; width:2rem; height:.5rem;}
		}
		h3 { color:$color-white; font-size:1rem; font-weight:400; margin-bottom:0; //text-transform:uppercase; 
			@include breakpoint(medium){ font-size: 1.25rem; }
			@include breakpoint(large){ font-size: 1.5rem; }
			a { color: scale-color($color-main, $lightness: 75%);
				&:hover { color: scale-color($color-main, $lightness: 60%); }
			}
		} 
	}

	section { margin-bottom:3rem;
			@include breakpoint(large){ flex:0 1 xy-cell-size(8/12); padding:0 1.5rem; }
	}

	aside { 
		@include breakpoint(large){ flex:0 1 xy-cell-size(4/12); padding:0 1.5rem; }
	}
}

// Payment Plan
.payment-plan {
	.hero { background-image: url(../Images/img/hero-banner-intro.jpg), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); background-position:100% 30%, 0 0; background-size: auto 130%, auto auto;
		.webp & { background-image: url(../Images/img/hero-banner-intro.webp), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); }
	}
}

// Marketing Rules
.marketing-rules {
	.hero { background-image: url(../Images/img/hero-banner-marketing-rules.jpg); background-size:cover; background-position:25% 50%;
		@include breakpoint(large){ background-position:0 50%; }
		.webp & { background-image: url(../Images/img/hero-banner-marketing-rules.webp); }
		&:after { display:none; }
	}
	.license {
		&-label, &-disclaimer { padding-top:.5rem; padding-bottom:.5rem; }
		&-row { display:flex; align-items:center; }
	}
	.licenses {
		&-table-head { display:flex; align-items:center; }
		&-table-head, &-table-body {
			.license-label { margin-right:2rem; }
		}
		&-table-body {
			.license-label { flex:0 0 auto; align-self:flex-start; margin-top:.25rem;
				span { background-color:#c8ab65; padding:.125rem .5rem; border-radius:.25rem; min-width:4rem; font-weight:500; text-align:center; display:inline-block; }
			}
			.license-disclaimer { flex:0 1 auto; }
		}
	}
}

// CPA Table
.cpa {
	&-data {  margin-bottom:1.5rem;
		@include breakpoint(large){ display:flex; }
		> div { 
			@include breakpoint(large){ padding:0 1rem; }
		}
	}
	&-table {  
		@include breakpoint(large){ flex:1 1 xy-cell-size(9/12); }
		table {
			tr {
				th, td { 
					&:nth-child(2) { text-align:center; }
				}
			}
		}
	}
	&-rates { flex: 0 1 xy-cell-size(3/12); display:flex; flex-wrap:wrap; justify-content:space-between;
		@include breakpoint(large){ display:block; }
		div { flex:0 0 30%; min-width:30%; background-color:$color-main; padding:.5em; margin:.5em 0; 
			@include breakpoint(large){ margin-bottom:1.5rem; }
			&:nth-child(3n+2) { margin-left:.25em; margin-right:.25em; 
				@include breakpoint(large){margin-left:0; margin-right:0;  }
			}

		}
		strong, span { display:block; text-align:center; color:$color-white; }
		strong { font-size:.875rem; font-weight:400; }
		span { font-size:1.25rem; 
			@include breakpoint(large){ font-size:1.75rem; }
		}
	}
}

// Form Styles
.form {
	@include breakpoint(small down){  margin: .5rem 0; padding:1rem .5rem; }
	h4 { flex: 0 0 auto; padding:.5em; font-size:1rem;  font-weight:500;
		@include breakpoint(medium){   margin-left:33%; margin-bottom:1rem; padding-left:1.75rem; text-align:initial; }
	}
	h5 { margin:1rem 0; border-bottom:1px solid $color-text-page; font-size:.875rem; font-weight:500; color:$color-text-page; }
	> div { 
		@include breakpoint(medium){ display:flex; align-items:center; }
		label { font-size:.75rem; padding:.5625rem .5rem .25rem .5625rem;
			@include breakpoint(medium){ flex:0 1 33%; font-size:.875rem; text-align:right; margin:0 0 1rem; padding:.5625rem 1rem .5625rem 0;  } //vertical-align:-25%; margin:0 .5rem;
		}
		&.checkbox { display:flex;
			/*> div { 
				&:nth-child(1) {  flex:0 1 auto; text-align:right; padding:.5625rem 0; margin:0 .5rem; align-self:flex-start;
					@include breakpoint(medium){ flex:0 1 33%; padding-left:1rem; padding-right:1rem; }
				}
				&:nth-child(2) {  flex:1 1 66%; }
			}*/
			label { flex:1 1 auto; text-align:initial; vertical-align:initial;  }
			label, input { cursor:pointer; }
			span { padding:.5rem .25rem; }

			&-inline { justify-content:flex-end; text-align:right;
				div { flex: 0 0 auto; padding:0 .5em;
					input { vertical-align:-10%;  cursor:pointer;
						@include breakpoint(medium){  margin:0 .5rem; }
					}
					label { padding:0; }
				}
			}
		}
		> div { flex:1 1 66%; padding:0 .5rem;
			&.date { 
				@include breakpoint(medium){ display:flex; align-items:center; flex-wrap:wrap; } 
				.validation-msg { width:100%; }
				&>.date-field {  position:relative; flex:1 1 180px;
					@include breakpoint(medium){ flex:0 1 180px; padding-right:1rem; } // add sum of padding on parent row to set width of datepicker wider then the field
					input { min-width:180px;  } // 180px is the harcoded width of the calendar
					img { position:absolute; right:.5rem; top:.75rem;
						@include breakpoint(medium){ right:1.5rem; }
					}
				}
			}

			input { }
			span { @include form-error; }
		}
		&.error {
			input, textarea { @include form-input-error; }
			span { display: block; }
		}
	}
	//.error { @include form-error; }
	.description { font-size:.75rem; padding:.5625rem 0.5rem .25rem 0.5rem;
		@include breakpoint(medium){ width:66%;margin-left:33%;font-size:.875rem; }
	}
}

// Register
.register {
	.hero { background-image: url(../Images/img/brand.png); background-position: right 24%; 
		@include breakpoint(xxlarge) { background-position: right 29%;  }
	}
	.callout.alert { display:block; margin:1rem 0; text-align:center; } // server errors
	.prevent-register-container { justify-content:center;
		section { 
			@include breakpoint(medium){ width:xy-cell-size(9/12); margin-left:auto; margin-right:auto; padding:0 1.5rem; }
			@include breakpoint(large){ flex:0 1 xy-cell-size(6/12); margin-left:0; margin-right:0; }
		}
	}
	&-form { border:10px solid #f3f3f3; padding:2rem 1rem 1rem; margin-top:0; position:relative;
		legend { position:absolute; text-align:center; top:-.75em; left:0; right:0; font-size:1rem; line-height:1; display:block;
			span { display:inline-block; padding: 0 1.5em; background-color:$color-white; text-transform:uppercase; }
		}
		h4 {
			a { }
		}
	}
	.submit { text-align: center;
	 a { background-color: $color-secondary; color:$color-black; font-weight: 500; color: $color-button-text; border-radius: .375em; transition:transform .15s ease-in; 
		&:hover {  transform: scale(1.125); color: $color-button-text;  }
	 } //text-transform: uppercase;
	}
}

.login {
	.hero { background-image: url(../Images/img/hero-banner-intro.jpg), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); background-position:100% 30%, 0 0; background-size: auto 130%, auto auto;
		.webp & { background-image: url(../Images/img/hero-banner-intro.webp), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); }
	}
	.main { justify-content:center; }
	section { 
		@include breakpoint(medium){ width:xy-cell-size(9/12); margin-left:auto; margin-right:auto; padding:0 1.5rem; }
		@include breakpoint(large){ flex:0 1 xy-cell-size(6/12); margin-left:0; margin-right:0; }
	}
	.submit { text-align: center; }
	[type='submit'] { //TODO: turn this into a placeholder style or mixin
		&.button { background-color: $color-secondary; color:$color-black; font-weight: 500; color: $color-button-text; border-radius: .375em; transition:transform .15s ease-in; }
		&:hover {  transform: scale(1.125); color: $color-button-text;  }
	}	
	&-links { display:flex; justify-content:center;
		a { display:block; flex:0 0 auto; padding:1em; font-size:.875rem; }
	}
	.callout.alert { display:block; margin:1rem 0; text-align:center;
		a { color: get-color(alert);}
	} // server errors
}

.forgot-password {
	.hero { background-image: url(../Images/img/hero-banner-intro.jpg), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); background-position:100% 30%, 0 0; background-size: auto 130%, auto auto;
		.webp & { background-image: url(../Images/img/hero-banner-intro.webp), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); }
	}
	.main { justify-content:center; }
	section { 
		@include breakpoint(medium){ width:xy-cell-size(9/12); margin-left:auto; margin-right:auto; padding:0 1.5rem; }
		@include breakpoint(large){ flex:0 1 xy-cell-size(6/12); margin-left:0; margin-right:0; }
	}
	.callout { text-align:center; }
	.submit { text-align: center; 
		a { background-color: $color-secondary; color:$color-black; font-weight: 500; color: $color-button-text; border-radius: .375em; transition:transform .15s ease-in; 
			&:hover {  transform: scale(1.125); color: $color-button-text;  }
		}
	}
	
}

.hello-bot {
	.hero { background-image: url(../Images/img/hero-banner-intro.jpg), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); background-position:100% 30%, 0 0; background-size: auto 130%, auto auto;
		.webp & { background-image: url(../Images/img/hero-banner-intro.webp), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); }
	}
	.main { justify-content:center; }
	section { text-align:center;
		@include breakpoint(medium){ width:xy-cell-size(9/12); margin-left:auto; margin-right:auto; padding:0 1.5rem; }
		@include breakpoint(large){ flex:0 1 xy-cell-size(6/12); margin-left:0; margin-right:0; }
		.button { margin:1rem 0;}
	}
	.g-recaptcha {
		div { margin:0 auto;}
	}
	.callout { text-align:center; }
	.submit { text-align: center; }
}

.help {
	.hero { background-image: url(../Images/img/brand.png); background-position: right 24%;
		@include breakpoint(xxlarge) { background-position: right 29%; }
	}
	section { 
		@include breakpoint(medium) { display:flex; padding:0; }
		.content { 
			@include breakpoint(medium) {flex:0 1 xy-cell-size(8/12); padding:0 1.5rem; }
			ol a { overflow-wrap:break-word; }
			.nowraplink { color:scale-color($color-main, $lightness:15%); display:inline-block; overflow-wrap:break-word; }
		} 
	}
	&-menu { 
		@include breakpoint(medium) { flex:0 1 xy-cell-size(4/12); padding:0 1.5rem; }
		h4 { font-size:.875rem; font-weight:500; padding-bottom:.5em; } //text-align:center;
		ul { list-style-type:none; margin:0; padding:0; }
		li {  padding:.5em; }
		a { display:block; }
		p { font-size:.875rem; margin-top:2em;}
		
	}
}

.unsubscribe {
	.hero { background-image: url(../Images/img/hero-banner-intro.jpg), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); background-position:100% 30%, 0 0; background-size: auto 130%, auto auto;
		.webp & { background-image: url(../Images/img/hero-banner-intro.webp), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); }
	}
	.main { justify-content:center; }
	section { 
		@include breakpoint(medium){ width:xy-cell-size(9/12); margin-left:auto; margin-right:auto; padding:0 1.5rem; }
		@include breakpoint(large){ flex:0 1 xy-cell-size(6/12); margin-left:0; margin-right:0; }
	}
	.callout { text-align:center; }
	.submit { text-align: center; }
}

// help menu highlight
$help-subjects:general, promote, reports, payments, about-plus500;
$subject-classes: [];
@each $subject in $help-subjects { 
	$subject-classes: append($subject-classes, #{"."+$subject+" .help-menu ."+$subject}, $separator:comma);
}
#{$subject-classes} { background-color: $color-main; 
	a { color:$color-white; cursor:default; }
}

.about {
	.hero { background-image: url(../Images/img/hero-banner-intro.jpg), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); background-position:100% 30%, 0 0; background-size: auto 130%, auto auto;
		.webp & { background-image: url(../Images/img/hero-banner-intro.webp), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); }
	}
	.main { justify-content:center; }
	section { 
		@include breakpoint(medium){ width:xy-cell-size(10/12); margin-left:auto; margin-right:auto; padding:0 1.5rem; }
		@include breakpoint(large){ flex:0 1 xy-cell-size(8/12); margin-left:0; margin-right:0; }
	}
	ul { color:$color-text; }
}

.contact {
	.hero { background-image: url(../Images/img/hero-banner-intro.jpg), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); background-position:100% 30%, 0 0; background-size: auto 130%, auto auto;
		.webp & { background-image: url(../Images/img/hero-banner-intro.webp), radial-gradient(43.77% 158.08% at 36.71% 90.14%,#0c2780 0,#0c2780 100%); }
	}
	.main { justify-content:center; }
	section { 
		@include breakpoint(medium){ width:xy-cell-size(10/12); margin-left:auto; margin-right:auto; padding:0 1.5rem; }
		@include breakpoint(large){ flex:0 1 xy-cell-size(8/12); margin-left:0; margin-right:0; }
	}
	.submit { text-align: center; 
		 a { background-color: $color-secondary; color:$color-black; font-weight: 500; color: $color-button-text; border-radius: .375em; transition:transform .15s ease-in; 
			&:hover {  transform: scale(1.125); color: $color-button-text;  }
		 }
	}
}

.result { 
	#preloader { display:none !important; }
}

.ajax__calendar { position:absolute; top:3.5rem; width:100%;  z-index:$z-index-control; 
	// overrride foundaion default styles for tables to return calender to it's original display
	* { box-sizing: content-box; } 
	.ajax__calendar_container, .ajax__calendar_body, .ajax__calendar_days, .ajax__calendar_months, .ajax__calendar_years { width:100%; }
	table { border-collapse:initial; margin:initial;
		tbody, thead { background-color: transparent; border:none; }
		tbody tr:nth-child(even) { background-color:transparent; }
		td { padding:initial; }
	}
	@include breakpoint(small only) {
		.ajax__calendar_dayname, .ajax__calendar_day, .ajax__calendar_year, .ajax__calendar_month { margin:0 auto; }
	}

}

// Marketing Tools
.marketing-tools { 
	.hero { background-image: url(../Images/img/cpa.png); background-position: right 60%;}	
	.main section {
		> div { margin-bottom:3rem;
			@include breakpoint(medium){ display:flex; align-items:center;
				div:nth-child(1){ flex:1 1 xy-cell-size(7/12); } //TODO: Replace these selectors with classes (i.e. text, image)
				div:nth-child(2) { flex:0 1 auto; max-width:xy-cell-size(5/12); }

				&:nth-child(even) {
					div:nth-child(1){ padding-right:3rem; }
				}
				&:nth-child(odd){
					div:nth-child(1) { order:1; padding-left:3rem; padding-right:0; }
					div:nth-child(2) { order:0; }
				}
			}

			div.no-image { padding-left:0 !important; padding-right:0 !important; } // it's ok to use !important
			div:nth-child(2) { margin:0; text-align:center; }
		}
		div.action { display: block; text-align:center; margin-bottom:0;
			.join-inline { max-width:initial; flex:none; @include hide-for(medium down); 
				div { flex:none; margin:0; padding:0 0 1rem 0; }
			}
		}
		
	}
}


// sidebar
.join-box { text-align:center; margin-bottom:3rem;
	p { padding:1em 0; margin:0; }
	.join-now { max-width:max-content; margin:0 auto; 
		
		span { transform:skew(15deg)}
	} 
}

.widget { border:10px solid #f3f3f3; padding:2rem 1rem 1rem; position:relative;
	h4 { position:absolute; text-align:center; top:-.75em; left:0; right:0; font-size:1rem; line-height:1;
		span { display:inline-block; padding: 0 1.5em; background-color:$color-white; text-transform:uppercase; }
	}
	li, p { color:$color-text-sidebar; }
	ul { margin-bottom:0; }
	li { margin:0; padding:0 0 1em 0;}
}


// Footer
.bg-footer { display:none; grid-area: 3/1/4/4; background-color:$color-white; /*position:relative; z-index: $z-index-interface-bg;*/ box-shadow:0 -10px 30px 0 rgba(0,0,0,0.15); //scale-color($color-main-dark, $alpha:-20%)
	@include breakpoint(medium){ display:block; }
	.page & { 
		@include breakpoint(medium){ grid-area: 4/1/5/4; }
	}
}
footer { display:none; grid-area: footer; /*position: relative;*/ padding: 1rem 4rem; justify-content: space-between; align-items: center; /*z-index: $z-index-interface;*/ color:$color-main; 
	@include breakpoint(medium){  display: flex; }
	p { padding: 0 1rem; margin: 0; font-size: .875rem; }
	.language-menu { position: relative; flex: 0 0 auto; display: flex; flex-direction: row; justify-content: center; z-index:$z-index-lang; font-size:1.5rem; font-variant: small-caps;
		a { text-decoration: none; color: $color-main;
			svg { vertical-align:-7%; height:1.125rem; width:1.125rem; margin:0 .5em; }
		}
		.language-indicator { padding-right:0; }
		div { position: absolute; bottom:0; left:0; max-height:0; overflow:hidden; min-width: 16em; overflow: hidden; padding:0 .5em; font-size: 1.5rem; transition: all ease-in 0.5s;
			background-color: transparent; color:$color-white; box-shadow: none; transform: translateY(-.25rem);
		}
		h5 { font-size: 1rem; flex: 0 0 100%; font-weight: 300; display: flex; justify-content: center; padding: 1em .5em;
			span { flex: 1 0 50%; text-align: center;
				&::first-letter { text-transform:lowercase; }
			}
			a { display:none; } //font-size: 1rem; opacity:0.5; 
		}
		&:hover div { background-color: scale-color($color-main, $alpha: -2.5%); transform: translateY(- .25rem); max-height:20em; box-shadow: 0 0 6px 6px scale-color($color-main-dark, $alpha: -50%); padding: .5em; }
		ul { margin: 0; padding: 0; list-style-type: none; display: flex; flex-wrap: wrap; }
		li { margin: 0; padding: 0; white-space: nowrap; flex: 0 0 25%; text-align: center;
			a { line-height: 1.5; display: inline-block; min-width: 2em; vertical-align: 25%; padding: .25em;  color:$color-white;
				&:hover { background: rgba(255, 255, 255, 0.25); }
			}
			&.language-toggler { display:none; }
		}
	}
	.consent-related-links .link { color:$color-main; font-size: .875rem; margin-right:.5rem;
		&:last-child { margin-right:0; }
		&:hover { text-decoration:underline; cursor:pointer; }
	}
}

// Misc.
.grecaptcha-badge {z-index:$z-index-captcha; }

// Cookie Banner
.cookie-banner { grid-area: 3/1/4/4; position: relative; bottom:0; padding:1em; z-index:$z-index-cookie; background-color:transparentize($color-main, 0.1); transition:bottom .3s ease-in;
	@include breakpoint(medium){ position:fixed; width:100vw;}
	//.home & {  }
	.page & { position:fixed; width:100vw; }
	
	div { display:flex; max-width:1440px; margin:0 auto; align-items:center; font-size:.875rem; color:$color-white; 
		@include breakpoint(medium){ justify-content:center; }
	}
	p { flex:1 1 50%; padding:0 1em 0 0; margin:0; 
		@include breakpoint(medium){ flex:0 1 auto;}
		a { text-decoration:underline; color:rgba(255,255,255,0.85); 
			&:hover { color:rgba(255,255,255,0.5);}
		}
	}
	button {flex:0 0 auto; @include button; @include button-hollow; @include button-hollow-style($color-white, -10%); margin:0 0 0 1em; } // margin at the end to override the button mixin
}

// Screen Rotation // Not used at athe moment
.no-phone-landscape { display:none; 
	@media only screen and (max-height:23em){ display:block; position:fixed; height:100%; width:100%;  // Use this when there's no landscape mode for a page: <div class="no-phone-landscape"><!-- prevent landscape mode on phone --></div>
        background:$color-main url(../Images/img/rotate-screen.png) no-repeat center center/auto 50%; z-index:$z-index-top; top:0; left:0; right:auto; 
	}
}

.otFlat {
	// to prevent OnTrust cookie banner overlapping the captcha
	&.elevate-captcha {  z-index:$z-index-cmp-cookie !important; }
	&.touches-bottom { @include breakpoint(medium){ bottom:4rem !important; } }
}